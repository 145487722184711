<template>
  <div class="PracticeShowcase-bg">
    <div class="PracticeShowcase-bg-title">
      <p class="icon"></p>
      <p class="title">{{ $t('COFITcompany.title') }}</p>
      <p style="margin-bottom: 50px">{{ $t('COFITList.co_tit2') }}</p>
    </div>

    <VideoPop
      class="videoPop"
      v-if="showPop"
      @closeme="closeme"
      :pralist="pralist"
      :id="id"
    />

    <p class="title_1">{{ $t('COFITcompany.employees_50') }}</p>
    <p class="borbo"></p>
    <div class="video">
      <div class="video_cont" v-for="(item, index) in pralist1" :key="index">
        <img class="alternate_img" v-if="!item.video" :src="item.pic" alt="">
        <iframe
          v-if="item.video"
          name="iframeName"
          ref="iframe"
          id="iframe"
          class="ifvideo"
          :src="item.video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <h6 style="margin: 10px">{{ $t('COFITcompany2023.' + item.cnname) }}</h6>
        <p style="margin: 10px">{{ $t('COFITcompany2023.' + item.desc) }}</p>
      </div>
    </div>
    <p class="title_1">{{ $t('COFITcompany.employees_500') }}</p>
    <p class="borbo"></p>
    <div class="video">
      <div class="video_cont" v-for="(item, index) in pralist2" :key="index">
        <img class="alternate_img" v-if="!item.video" :src="item.pic" alt="">
        <iframe
          name="iframeName"
          ref="iframe"
          id="iframe"
          class="ifvideo"
          :src="item.video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          v-else
        ></iframe>
        <h6 style="margin: 10px">{{ $t('COFITcompany2023.' + item.cnname) }}</h6>
        <p style="margin: 10px" v-html="$t('COFITcompany2023.' + item.desc)"></p>
      </div>
    </div>
    <p class="title_1">{{ $t('COFITcompany.employees_5000') }}</p>
    <p class="borbo"></p>
    <div class="video">
      <div class="video_cont" v-for="(item, index) in pralist3" :key="index">
        <iframe
          name="iframeName"
          ref="iframe"
          id="iframe"
          class="ifvideo"
          :src="item.video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        
        <h6 style="margin: 10px">{{ $t('COFITcompany2023.' + item.cnname) }}</h6>
        <p style="margin: 10px" v-html="$t('COFITcompany2023.' + item.desc)"></p>
      </div>
    </div>
  </div>
</template>

<script>
import VideoPop from '../components/VideoPop.vue'


export default {
  components: { VideoPop }
  ,
  data () {
    return {
      id: 'id',
      showPop: false,
      pralist1:[
        {
          id: 1,
          cnname: 'company_name1',
          desc : 'company_vid_desc_1',
          video: 'https://www.youtube.com/embed/HOwtbcYEUQE?si=iSZ63Dvy_cSOX_q_',
          pic: require('../assets/images/CO-FITvideo-img/video_img_1.webp')
        },
        {
          id: 2,
          cnname: 'company_name2',
          desc : 'company_vid_desc_2',
          video: '',
          pic: require('../assets/images/CO-FITvideo-img/kaitak.webp')
        },
        {
          id: 3,
          cnname: 'company_name3',
          desc : 'company_vid_desc_3',
          video: 'https://www.youtube.com/embed/9aGEdHNCUc8?si=X_Fj__T6hPEnhOKN',
          pic: require('../assets/images/CO-FITvideo-img/video_img_9.webp')
        },
      ],
      pralist2:[
        {
          id: 4,
          title: 'co_tit4',
          cnname: 'company_name4',
          desc : 'company_vid_desc_4',
          video: 'https://www.youtube.com/embed/tDmCe3GXLOw?si=ilxec3f3DVRibSfX',
          pic: require('../assets/images/CO-FITvideo-img/video_img_2.webp')

        },
        {
          id: 5,
          cnname: 'company_name5',
          desc : 'company_vid_desc_5',
          video: 'https://www.youtube.com/embed/5wkeqxFO3_4?si=ALpeL5QHGCXegzKi',
          pic: require('../assets/images/CO-FITvideo-img/video_img_3.webp')

        },
        {
          id: 6,
          cnname: 'company_name6',
          desc : 'company_vid_desc_6',
          video: 'https://www.youtube.com/embed/w54HZc_oTGU?si=8Z_zHkT8a2DgkfqP',
          pic: require('../assets/images/CO-FITvideo-img/video_img_4.webp')
        },
        {
          id: 7,
          title: 'co_tit5',
          cnname: 'company_name7',
          desc : 'company_vid_desc_7',
          video: '',
          pic: require('../assets/images/CO-FITvideo-img/tesla.webp')
        },
      ],
      pralist3:[
        {
          id: 8,
          cnname: 'company_name8',
          desc : 'company_vid_desc_8',
          video: 'https://www.youtube.com/embed/X1-MqAWdOBA?si=mK_ntPjPQiwHBJOz',
          pic: require('../assets/images/CO-FITvideo-img/video_img_10.webp')
        },
        {
          id: 9,
          cnname: 'company_name9',
          desc : 'company_vid_desc_9',
          video: 'https://www.youtube.com/embed/KCSW-oGdfLs?si=7xdI0mUblobxfhTW',
          pic: require('../assets/images/CO-FITvideo-img/video_img_8.webp')

        },
        {
          id: 10,
          cnname: 'company_name10',
          desc : 'company_vid_desc_10',
          video: 'https://www.youtube.com/embed/taT_EjhlI4U?si=AIgGHYk6wA5noYnc',
          pic: require('../assets/images/CO-FITvideo-img/video_img_6.webp')
        },
      ]
    }
  },
  methods: {
    toggleModal: function (id, index) {
      this.showPop = !this.showPop;
      // this.colseiframe=true
      this.id = id
      this.index = index


    },
    closeme: function () {
      this.showPop = false;
    }

  }
}
</script>

<style scoped lang="scss">
.PracticeShowcase-bg {
  .video {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 80%;
    margin-left: 10%;
  }
  .video_cont {
    width: 50%;
    margin-bottom: 70px;

    h6 {
      font-size : 20px;
      color : #707070;
    }
    img {
      width: 100%;
      height: 100%;
    }
    .ifvideo {
      border-radius: 15px;
      width: 90%;
      height: 300px;
    }
    .alternate_img {
      height: 300px;
      border-radius: 20px;
    }
  }
  .PracticeShowcase-bg-title {
    .icon {
      width: 40px;
      height: 40px;
      margin: 40px auto 5px;
      background: url('../assets/images/Group 11846.svg') 50%/50% no-repeat;
    }
    .title {
      font-size: 24px;
      color: #1e30f6;
      font-family: 'PingFang-Heavy';
      letter-spacing: 2px;
      line-height: 24px;
      margin-bottom: 40px;
    }
  }
  .title_1 {
    font-size: 18px;
    // color: #1e30f6;
    text-align: left;
    margin: 0 auto;
      width: 75%;
    font-family: 'PingFang-Heavy';
    letter-spacing: 2px;
    line-height: 18px;
    margin-bottom: 10px;
  }
  .borbo {
      // position: absolute;
      // left: 50%;
      // bottom: 0px;
      // transform: translate(-50%, -50%);
      margin: 0 auto;
      width: 75%;
      height: 5px;
      background: #707070;
      margin-bottom: 40px;
      border-radius: 10px;
  }
}
@media screen and (max-width: 1000px) {
  .PracticeShowcase-bg {
    .video {
      flex-direction: column;
      justify-content: space-between;
    }
    .video_cont {
      width: 90%;
     // //height: 300px;
      margin-bottom: 70px;
      margin-left: 5%;

      img {
        border-radius: 30px;
        width: 100%;
        height: 100%;
      }
    }
  }
}
@media screen and (max-width: 520px) {
  .PracticeShowcase-bg {
    .video {
      width: 90%;
      margin-left: 5%;
      margin-bottom:10px;
      .video_cont {
        width: 100%;
       // //height: 300px !important;
        margin-bottom: 50px;
        margin-left: 0%;
        margin-bottom: 70px;
        img {
          width: 100%;
          height: 100%;
        }
        .ifvideo {
          border-radius: 15px;
          width: 100%;
         // //height: 300px;
        }
      }
    }
  }
}
</style>